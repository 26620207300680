<style scoped>
.holding-widget .col:first-child .card-val{@apply !pl-0}
.card-val{@apply lg:px-[10%];}
.card-val label{@apply text-xs text-[#56585A] }
.card-val .val{@apply text-2xl;}
.card-val small.val{@apply text-xs font-bold ml-2;}
</style>
<template>
    <div class="holding-widget mb-2 lg:mb-5 lg:divide-x dark:divide-gray-500 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-3 lg:gap-6 justify-between">
          <div class="col">
            <div class="card-val">
              <label class="secondary-color">Total Investment</label>
              <div class="text-xl primary-color dark:text-[#d7d9db] flex flex-wrap">₹ {{ getTotalInvestment ? $common.ruppesFormat(parseFloat(getTotalInvestment).toFixed(2)) : '0.00' }}</div>
            </div>
          </div>
          <div class="col">
            <div class="card-val">
              <label class="secondary-color">Current Value</label>
              <div class="text-xl primary-color dark:text-[#94A3B8] flex flex-wrap">₹ {{ $formula.totalCurrentValue(getHoldingsData) ? $common.ruppesFormat($formula.totalCurrentValue(getHoldingsData)) : '0.00' }}</div>

            </div>
          </div>
          <div class="col">
            <div class="card-val">
              <div>
                <label class="secondary-color">Today's P&L</label>
              </div>
              <div class="flex flex-wrap items-baseline">
                <span class="text-lg" :class="$common.removeComma($formula.totalTodaysPnl(getHoldingsData)) > 0 ? 'green-color' : $common.removeComma($formula.totalTodaysPnl(getHoldingsData)) < 0 ? 'red-color' : 'primary-color'">₹ {{ $formula.totalTodaysPnl(getHoldingsData) ? $common.ruppesFormat($formula.totalTodaysPnl(getHoldingsData)) : '0.00' }}</span>
                <span class="ml-2" :class="$common.removeComma($formula.totalTodaysPnlChg(getHoldingsData)) > 0 ? 'green-color' : $common.removeComma($formula.totalTodaysPnlChg(getHoldingsData)) < 0 ? 'red-color' : 'primary-color'">({{ $formula.totalTodaysPnlChg(getHoldingsData) ? $formula.totalTodaysPnlChg(getHoldingsData) : '0.00' }}%)</span>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card-val">
              <div>
                <label class="secondary-color">Net P&L</label>
              </div>
              <div class="flex flex-wrap items-baseline">
                <span class="text-lg" :class="$common.removeComma($formula.totalNetPnl(getHoldingsData)) > 0 ? 'green-color' : $common.removeComma($formula.totalNetPnl(getHoldingsData)) < 0 ? 'red-color' : 'primary-color'">₹ {{ $formula.totalNetPnl(getHoldingsData) ? $common.ruppesFormat($formula.totalNetPnl(getHoldingsData)) : '0.00' }}</span>
                <span class="ml-2" :class="$common.removeComma($formula.totalNetPnlChg(getHoldingsData)) > 0 ? 'green-color' : $common.removeComma($formula.totalNetPnlChg(getHoldingsData)) < 0 ? 'red-color' : 'primary-color'">({{ $formula.totalNetPnlChg(getHoldingsData) ? $formula.totalNetPnlChg(getHoldingsData) : '0.00' }}%)</span>
              </div>
            </div>
          </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters('holdings',["getHoldingsData","getTotalInvestment","getTotalcurrentValue","getTotalPnlChange","getDaysPnlChange",
        "getDaysPnl","getDaysPnl","getTotalPnl","getTotalPnlChange","getDaysPnlChange" , 'getHoldingsObject']),
    },
    methods:{
      holdWatch(data, token) {
            if (data && this.getHoldingsObject) {
                let ctData = this.getHoldingsObject
                if (ctData) {
                    let hasKey = token in ctData;
                    if (data && token && hasKey && data[token]?.lp) {
                        ctData[token].ltp = data[token].lp
                        ctData[token].lp = data[token].lp
                    }
                    if (data && token && hasKey && data[token]?.c) {
                        ctData[token].pdc = data[token].c
                    }
                    this.$store.commit('holdings/setHoldingsData', Object.values(ctData))
                }
            }
        }
    },
    created(){
      if(this.$route.path == '/dashboard'){
        window.dashBoard_holdings = {
        callback: this.holdWatch,
      }
      }
    },
    unmounted(){
      delete window.dashBoard_holdings
    }
}
</script>
