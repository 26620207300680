import { createApp } from "vue";
import "./style.css";
import App from "./App.vue";
import router from "./router";
import _store from "./_store";
import "./assets/tailwind/tailwind.css";
import Vue3Tour from 'vue3-tour'
import 'vue3-tour/dist/vue3-tour.css'
import Notifications from "@kyvg/vue3-notification";
import "tippy.js/dist/tippy.css";
import VueTippy from "vue-tippy";
import vClickOutside from "click-outside-vue3"
import 'tippy.js/animations/scale.css'
import 'tippy.js/themes/light.css'
import velocity from 'velocity-animate'
import './tooltip.css'

// otp
import VOtpInput from "vue3-otp-input";

// .use(Vue3Tour)
const app = createApp(App)

app.use(router)
// app.use(Notifications)
app.use(Notifications, { velocity })
app.use(VueTippy, { defaultProps: { placement: "top", arrow: true } })
app.use(_store)
app.use(vClickOutside)
app.use(Vue3Tour)


import btnLoader from "./components/btnloader.vue"
import Autocomplete from './components/autocomplete.vue'
import loginmessage from './components/loginmessage.vue'
import feedback from './components/feedback.vue'
import spinner from './components/spinner.vue'
import holdingwidget from './components/holdingwidget.vue'
import Holdingchart from './components/holdingchart.vue'
import box from './components/shared/box.vue'
import Progress from './components/progress.vue'
import Tabs from './components/tabs.vue'
import positionwidget from './components/positionwidget.vue'

app.component('Autocomplete', Autocomplete)
app.component('btnLoader', btnLoader);
app.component('loginmessage', loginmessage)
app.component('feedback', feedback)
app.component('spinner', spinner)
app.component('holdingwidget', holdingwidget)
app.component('Holdingchart', Holdingchart)
app.component('box', box)
app.component('Progress', Progress)
app.component('Tabs', Tabs)
app.component('positionwidget', positionwidget)
app.component('v-otp-input', VOtpInput);

import  common from './mixins/common.js'
app.config.globalProperties.$common = common
import formula from "./mixins/formula.js";
import fingerPrint from './mixins/fingerPrint.js'
app.config.globalProperties.$fingerPrint = fingerPrint
app.config.globalProperties.$formula = formula
app.config.globalProperties.$tours = Vue3Tour

app.mount("#app");

// app.config.errorHandler = (err, instance, info) => {
//     console.error({err, instance, info})
//   }
