<style scoped>
.holding-widget .col:first-child .card-val{@apply !pl-0}
.card-val{@apply px-[10%];}
.card-val label{@apply text-xs text-[#56585A];}
.card-val .val{@apply text-2xl;}
.card-val small.val{@apply text-xs font-bold ml-2;}
</style>
<template>
    <div class="row mb-[20px] !border-width-0 lg:divide-x grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-3 lg:gap-6 justify-between">
        <div class="col"><div class="card-val"><label>Realised P&L</label><div class="text-lg primary-color">₹ {{ $common.getRealisedPnl(positions) || '0.00' }}</div></div></div>
        <div class="col"><div class="card-val"><label>Unrealised P&L</label><div class="text-lg primary-color">₹ {{ $common.getUnRealisedPnl(positions) || '0.00' }}</div></div></div>
        <div class="col"><div class="card-val"><label>MTM</label><div class="text-lg text-[#4caf50]" :class="$common.removeComma($common.getTotalDaysPnl(positions)) > 0 ? 'green-color' : $common.removeComma($common.getTotalDaysPnl(positions)) == 0 ? 'primary-color' :  'clr-red'">₹ {{ $common.getTotalDaysPnl(positions) || '0.00' }}</div></div></div>
        <div class="col"><div class="card-val"><label>Total P&L</label><div class="text-lg" :class="$common.removeComma($common.totalGroup(positions)) > 0 ? 'green-color' : $common.removeComma($common.totalGroup(positions)) == 0 ? 'primary-color' : 'clr-red'">₹ {{ $common.totalGroup(positions) || '0.00' }}</div></div></div>
    </div>
</template>


<script>
import {mapGetters} from 'vuex'
export default {
    computed: {
        ...mapGetters('positions',['getPositionsData']),
    },
    props: {
        positions: {
            type:Array,
            required:true
        },
        required: false,
    },
}
</script>
